import React, { useState,useContext } from 'react';
import './About.css';
import { Context } from '../../../App';

function About() {
  const { totalAttendance } = useContext(Context);

  return (
    <div className="AppSection">
      <div className='AboutTextBlock'>
        <h3>About us</h3>
        <p className='p1'>We are a motion design studio based in the Netherlands, dedicated to crafting unforgettable experiences that captivate live audiences. From concept creation to live execution, we push the boundaries of what's possible in live entertainment, ensuring every project is a success.</p>
        <p className='p1'>With a keen eye for detail and an open approach to the creative process, we take pride in delivering exceptional results that leave a lasting impact.</p>
      </div>
    </div>
  );
}

export default About;

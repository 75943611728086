import React, { useState } from 'react';
import './Services.css';

import { Link } from 'react-router-dom';

function Services() {


  return (
    <div className="AppSection">
      <div className='AboutTextBlock'>
        <h3>Motion graphics</h3>
        <p className='p1 nomargin'>We focus on delivering top-tier content and graphic assets tailored to the creative direction of the project.</p>

        <h3>Live</h3> 
        <p className='p1 nomargin'>We specialize in creating memorable and impactful live visuals, enhancing every moment in perfect sync and with your creative vision in mind. With thousands of hours of show experience, we maximize the full potential of every installation.</p>

        <h3>Design</h3>
        <p className='p1 nomargin'>We conceptualize and execute show designs that are both realistic and spectacular.</p>

      </div>
    </div>
  );
}

export default Services;

import React, { useState } from 'react';
import './Contact.css';


function Contact() {
  return (
    <div className="AppSection">
      <div className='Contact'>
        <div>
          <h3>Get in Touch</h3>
          <p className='p1 nomargin'>Interested in bringing your project to life with us? We’re excited to hear from you!</p>
        </div>
        <div className='HorizontalDevider'>
          <div className='WidthHalfLocation'>
            <h3>Rotterdam</h3>
            <a className='b4' href="mailto:info@vizionx.eu">info@vizionx.eu</a>
            <a className='b4' href="tel:+31642035015">+31 642 035 015</a>
            <p className='b4'>VAT: NL004097551B59 </p>
            <p className='b4'>COC: 85458139 </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;

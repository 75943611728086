import React, { useState } from 'react';
import './Home.css';
import { ReactComponent as LogoBig } from "../../../LocalAssets/VIZIONXWHITE.svg";


import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="AppSection">
      <video className="backgroundvideo" autoPlay loop muted>
        <source 
          src="https://firebasestorage.googleapis.com/v0/b/website-a489a.appspot.com/o/Generic%2Fwebsite%20bg%20video%20v2.mp4?alt=media&token=8528acf3-34c5-48be-9eab-2e7676124494" 
          type="video/mp4" 
        />
      </video>
      <div className='TitleSection NoSelect'>
        <p className='landertext'>MOTION DESIGN STUDIO</p>
      </div>
    </div>
  );
}

export default Home;

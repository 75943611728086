import React, { useState } from 'react';
import './ProjectFocus.css';
import { TfiClose } from "react-icons/tfi";
import { FaPlay } from "react-icons/fa";

function ProjectFocus({ Details, Type, close }) {
  const [showVideo, setShowVideo] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  if (!Details) return null;

  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return '';
    const date = timestamp.toDate();
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
    });
  };

  const openSocialMedia = () => {
    if (Details.SocialMedia && Details.SocialMedia.length > 0) {
      window.open(Details.SocialMedia[0], '_blank');
    }
  };

  return (
    <div className="ProjectFocus">
      {Type === "Show" && (
        <div className="ShowFocus">
          <button onClick={close} className='CloseButton'>
            <TfiClose className='CloseIcon' />
          </button>
          <img src={Details.ShowCover} className="BackgroundImage NoSelect" alt="Show Cover" />
          <div className='VerticalSplit'>
            <div>
              <img onClick={openSocialMedia} src={Details.ShowLogo} className="ShowFocusShowLogo NoSelect" alt="Show Logo" />
              <h3>{Details.Description}</h3>
              <p className='p3'>{Details.Location}</p>
              <p className='p3'>{formatDate(Details.Date)}</p>
              {Details.ShowVideo && Details.ShowVideo.trim() !== "" && (
                <button className='b2 MarginTop2' onClick={() => setShowVideo(true)}>
                  <FaPlay /> Aftermovie
                </button>
              )}
            </div>
            <div className='Hsplit'>
              <div className='AdditionalImages NoSelect'>
                <div className='ExtraImagesGrid'>
                  {Details.ShowExtraImages.map((ShowExtraImage, index) => (
                    <img 
                      key={index} 
                      src={ShowExtraImage} 
                      className="ExtraImage NoSelect" 
                      alt="Extra"
                      onClick={() => setSelectedImage(ShowExtraImage)} // Open image in modal
                    />
                  ))}
                </div>
              </div>
              <div className='ActsList'>
                <p className='p3w NoSelect'>Genre:</p>
                <div className='TextDataList'>
                  {Details.Genre.map((Genre, index) => (
                    <p key={index} className='p3'>• {Genre}</p>
                  ))}
                </div>
                <p className='p3w NoSelect'>Performed Visuals For:</p>
                {Details.Acts.map((Act, index) => (
                  <p key={index} className='p3'>• {Act}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {(showVideo || selectedImage) && (
        <div className="MediaModal">
          <div className="MediaModalContent">
            <button onClick={() => { setShowVideo(false); setSelectedImage(null); }} className='CloseButton'>
              <TfiClose className='CloseIcon Margin1' />
            </button>
            {showVideo && (
              <iframe
                width="1280"
                height="720"
                src={`https://www.youtube.com/embed/${Details.ShowVideo.split('v=')[1]}?enablejsapi=1`}
                title="YouTube video player"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            )}
            {selectedImage && (
              <img src={selectedImage} className="FullscreenImage" alt="Expanded View" />
            )}
          </div>
        </div>
      )}
        {Type === "Artwork" && (
        <div className="ArtworkFocus">
          <button onClick={close} className='CloseButton'>
            <TfiClose className='CloseIcon' />
          </button>
          <div className='HorizontalSplit'>
            <div className='ArtworkTextSection'>
              <img src={Details.ArtFull} className="HighlightImage" alt="Artwork" />
            </div>
            <div className='ArtworkTextSection'>
              <div>
                <h3>{Details.Name}</h3>
                <p className='p1'>{Details.Artist}</p>
              </div>
              <p className='p1'>{Details.Description}</p>
              <div>
                <p className='p3w'>Color Palette</p>
                <div className='ColorPaletteRow'>
                  {Details.ColorPalette.map((Color, index) => (
                    <p key={index} style={{background: Color}} className='HexCodeText'>{Color}</p>
                  ))}
                </div>
              </div>
                <div>
                <p className='p3w'>Software</p>
                <div className='TextDataList'>
                  {Details.Software.map((Software, index) => (
                    <p key={index} className='p3'>• {Software}</p>
                  ))}
                </div>
              </div>
              <div>
                <p className='p3w'>See on</p>
                <div className='TextDataList'>
                {Object.entries(Details.link).map(([key, value], index) => (
                    <p key={index} className='p3'>
                      <a className='p3' href={value} target="_blank" rel="noopener noreferrer">
                        {key}
                      </a>
                    </p>
                  ))}
                  </div>
              </div>
            </div>
          </div>
        </div>
      )}



    </div>
  );
}

export default ProjectFocus;
